import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Formik, Form } from 'formik';
import { httpClient } from 'src/app/helpers';
import { Page, InputField, Button } from 'src/app/components';
import * as Yup from 'yup';

import EQIT from 'src/assets/images/equilibre_iphone_tablet-cut.png';
import logo from 'src/assets/images/equilibre_logo.png';
import badgeSharecare from 'src/assets/images/badge_unverified.png';
import EQPasteur from 'src/assets/images/equilibre_pasteur_logo.png';
import EQLIUser from 'src/assets/images/equilibre_icon_user.png';
import EQLILock from 'src/assets/images/equilibre_icon_lock.png';
import EQLIHand from 'src/assets/images/equilibre_icon_hand.png';
import EQLIMessage from 'src/assets/images/equilibre_icon_message.png';
import EQLIBook from 'src/assets/images/equilibre_icon_book.png';
import EQLIBoard from 'src/assets/images/equilibre_icon_board.png';
import EQLIFood from 'src/assets/images/equilibre_food.png';
import EQLISleep from 'src/assets/images/equilibre_sleep.png';
import EQLISport from 'src/assets/images/equilibre_sport.png';
import EQLIStress from 'src/assets/images/equilibre_stress.png';
import EQIphones from 'src/assets/images/equilibre_double_iphones.png';
import EQLIThumb from 'src/assets/images/equilibre_icon_thumb.png';
import EQLICheck from 'src/assets/images/equilibre_icon_check.png';
import EQLIBusiness from 'src/assets/images/equilibre_icon_business.png';
import EQLIDashboard from 'src/assets/images/equilibre_icon_dashboard.png';
import EQLIServices from 'src/assets/images/equilibre_icon_services.png';
import EQExpertML from 'src/assets/images/equilibre_avatar_manon_lenain.png';
import EQExpertNU from 'src/assets/images/equilibre_avatar_nathalie_uytterhaegen.png';
import EQExpertBT from 'src/assets/images/equilibre_avatar_beate_thormeier.png';
import EQExpertAS from 'src/assets/images/equilibre_avatar_aurelien_souppez.png';
import EQExpertSP from 'src/assets/images/equilibre_avatar_simon_spychala.png';
import EQPodcast from 'src/assets/images/equilibre_iphone_podcast.png';
import EQPoster from 'src/assets/images/equilibre_poster_video.png';
import EQPlay from 'src/assets/images/equilibre_icon_play_1.png';
import EQIlLaptop from 'src/assets/images/equilibre_illustration_laptop.png';
import EQIlLanding from 'src/assets/images/equilibre_illustration_landing.png';
import EQIlAd from 'src/assets/images/equilibre_illustration_ad.png';

import EQVideo from 'src/assets/videos/equilibre_video.mp4';

const requiredFieldMessage = 'Ce champ est requis'

const CONTACT_US_SCHEMA = Yup.object().shape({
    firstName: Yup.string().max(250, 'forms.too_long').required(requiredFieldMessage),
    lastName: Yup.string().max(250, 'forms.too_long').required(requiredFieldMessage),
    from: Yup.string().email('Veuillez saisir une adresse email valide').max(250, 'forms.too_long').required(requiredFieldMessage),
    zip: Yup.string().max(250, 'forms.too_long').required(requiredFieldMessage),
    companyName: Yup.string().max(250, 'forms.too_long').required(requiredFieldMessage),
    message: Yup.string().max(250, 'forms.too_long').required(requiredFieldMessage),
});

const INITIAL_CONTACT_VALUES = {
    firstName: '',
    lastName: '',
    from: '',
    zip: '',
    companyName: '',
    message: '',
};

let listenerVideoPlayAdded = false;
let eventVideoPlaySent = false;
let listenerVideoEndAdded = false;

function addVideoPlayListener() {
  if (typeof document !== `undefined` && !listenerVideoPlayAdded) {
    const video = document.querySelector('video');
    if (video) {
      video.addEventListener('play', () => {
        if (window.gtag && !eventVideoPlaySent) {
          window.gtag('event', 'Video_equilibre_lp_play');
          eventVideoPlaySent = true;
        }
        listenerVideoPlayAdded = true;
      });
    }
  }
}

function addVideoEndedListener() {
  if (typeof document !== `undefined` && !listenerVideoEndAdded) {
    const video = document.querySelector('video');
    if (video) {
      video.addEventListener('ended', () => {
        if (window.gtag) window.gtag('event', 'Video_equilibre_lp_ended');
        listenerVideoEndAdded = true;
      });
    }
  }
}


const Index = () => {
    const { t } = useTranslation('equilibre');

    const [contactSuccess, setContactSuccess] = useState(null);

    const handleContact = async (values, { setSubmitting, resetForm }) => {
        if (window.gtag) window.gtag('event', 'Click_contact_us');
        setSubmitting(true);

        try {
        const { status } = await httpClient.post('/contact/landing?origin=equilibre', values);

        if (status === 200) {
            setSubmitting(false);
            setContactSuccess('SUCCESS');
            resetForm();
        } else {
            throw Error('Contact request failed.');
        }
        } catch (error) {
        setContactSuccess('ERROR');
        setSubmitting(false);
        }
    };

    const playVideo = (e) => {
        const poster = document.getElementById(`poster`);
        poster.style.display = `none`;
        const video = document.getElementById(`video`);
        video.play();
    }


    useEffect(() => {
        addVideoPlayListener();
        addVideoEndedListener();

        // ugly fix to make sure that the page will scroll to the correct anchor (#) upon upening...
        const urlTokens = location.href.split('#');
        if (urlTokens[1]) {
            location.hash = '';
            location.hash = urlTokens[1];
        }
    });



    //Breaking points :
    //1375px

    return (
        <Page title="Programme EQUILIBRE">
            <Page.Main>
                <div style={{
                    backgroundColor: '#f3f7f9',
                    fontFamily: 'Open Sans, sans-serif'
                    }}>
                    <div id="home" style={{
                        width: '100%',
                        minHeight: '100vh',
                        paddingTop: '82px'
                    }}>
                        <div className='grid grid-cols-1 xl:grid-cols-2'>
                            <div className='p-8 md:pl-12 xl:pl-20 md:p-28 xl:pr-0 2xl:pr-28 mt-8 xl:mt-0'>
                                <div className='pb-14 xl:pb-10' style={{
                                    fontSize: '36px',
                                    fontWeight: 700,
                                    lineHeight: '46px',
                                    color: '#353A60'
                                }}>
                                    <span style={{color: '#00bfa5'}} >Renforcez</span> le capital <span style={{color: '#00bfa5'}} >bien-être</span> et santé de tous vos collaborateurs
                                    <span>
                                        <span className='mr-2'>
                                            &nbsp;avec le Programme
                                        </span>
                                        <img src={logo} alt="Logo Equilibre" className='inline-block' style={{
                                            height: '31px',
                                            marginTop: '-10px'
                                        }}/>
                                    </span>
                                </div>
                                <div className='xl:mt-4 xl:mb-28'>
                                    <a className='inline-block align-top mr-2 mb-2' style={{
                                        color: 'white',
                                        width: '200px',
                                        height: '60px',
                                        borderRadius: '10px',
                                        backgroundColor: '#feac5d',
                                        fontSize: '18px',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                        padding: '18px',
                                        textAlign: 'center',
                                    }} href='#demo' >
                                        <div className='flex'>
                                            <img src={EQPlay} alt='Equilibre Play Icon' style={{
                                                height: '40px',
                                                marginTop: '-10px',
                                                marginRight: '10px'
                                            }}/>
                                            Voir la démo
                                        </div>
                                    </a>
                                    <a className='inline-block align-top' style={{
                                        color: 'white',
                                        width: '200px',
                                        height: '60px',
                                        borderRadius: '10px',
                                        backgroundColor: 'rgb(0, 191, 165)',
                                        fontSize: '18px',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                        padding: '18px',
                                        textAlign: 'center',
                                    }} href='#contact' >
                                        Nous contacter
                                    </a>
                                </div>
                                <div style={{
                                    border: '1px solid #E5ECEE',
                                    marginTop: '50px'
                                }}></div>
                                <div className='block md:flex justify-between items-center' style={{
                                    padding: '40px'
                                }}>
                                    <div style={{
                                            fontSize:'16px',
                                            lineHeight: '24px',
                                            color: '#353A60'
                                        }}>
                                        <span>Créé par <span style={{fontWeight: 700}}>Sharecare</span></span><br/>
                                        <span>et le <span style={{fontWeight: 700}}>Centre Prévention Santé Longévité</span></span><br/>
                                        <span style={{fontWeight: 700}}>de l'Institut Pasteur de Lille</span>
                                    </div>
                                    <a className='inline-block' href="https://www.sharecare.fr" target="_blank">
                                        <img src={badgeSharecare} alt='Equilibre Sharecare Logo' className='object-contain hidden md:block mx-8' style={{
                                            width: '80px'
                                        }} />
                                    </a>
                                    <a className='inline-block' href="https://pasteur-lille.fr/centre-prevention-sante-longevite" target="_blank">
                                        <img src={EQPasteur} alt='Equilibre Pasteur Logo' className='object-contain hidden md:block' style={{
                                            width: '80px'
                                        }} />
                                    </a>
                                    <div className='flex mt-5 md:mt-0 block md:hidden'>
                                        <a className='inline-block' href="https://www.sharecare.fr" target="_blank">
                                            <img src={badgeSharecare} alt='Equilibre Sharecare Logo' className='object-contain mr-8' style={{
                                                width: '80px'
                                            }} />
                                        </a>
                                        <a className='inline-block' href="https://pasteur-lille.fr/centre-prevention-sante-longevite" target="_blank">
                                            <img src={EQPasteur} alt='Equilibre Pasteur Logo' className='object-contain' style={{
                                                width: '80px'
                                            }} />
                                        </a>
                                    </div>
                                </div>
                                <div style={{
                                    border: '1px solid #E5ECEE'
                                }}></div>
                            </div>
                            <div className='relative h-auto flex items-center justify-end'>
                                <img src={EQIT} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className='py-28 xl:pb-8 xl:px-28' style={{
                        backgroundColor: '#e5ecee',
                        width: '100%',
                        display: 'flex',
                        color: '#353A60'
                    }}>
                        <a className="equilibre-anchor" id="parcours"></a>
                        <div className='container mx-auto'>
                            <div className='grid grid-cols-1 xl:grid-cols-2'>
                                <div style={{
                                    fontFamily: 'Open Sans, sans-serif',
                                    fontSize: '36px',
                                    fontWeight: 700
                                }}>
                                    <div className='mb-8'>
                                        <span style={{
                                            color: '#00bfa5'
                                        }} >4 parcours</span> interactifs guidés par un assistant
                                    </div>
                                    <ul className='equilibre-custom-icon-list'>
                                        <li className='flex flex-row items-center mb-5'>
                                            <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                                <img src={EQLIUser} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                            </div>
                                            <p className='flex-1'>
                                                <span className='equilibre-bold'>Ouvert à tous</span> sur la base du volontariat
                                            </p>
                                        </li>
                                        <li className='flex flex-row items-center mb-5'>
                                            <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                                <img src={EQLIHand} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                            </div>
                                            <p className='flex-1'>
                                                <span className='equilibre-bold'>8 semaines d'accompagnement</span> par thématique
                                            </p>
                                        </li>
                                        <li className='flex flex-row items-center mb-5'>
                                            <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                                <img src={EQLIMessage} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                            </div>
                                            <p className='flex-1'>
                                                <span className='equilibre-bold'>Repères pratiques, challenges, quiz, vidéos</span> et <span className='equilibre-bold'>podcasts</span> de nos experts
                                            </p>
                                        </li>
                                        <li className='flex flex-row items-center'>
                                            <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                                <img src={EQLIBook} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                            </div>
                                            <p className='flex-1'>
                                            <span className='equilibre-bold'>Bibliothèque</span> de contenus
                                            </p>
                                        </li>
                                    </ul>
                                    <div className='relative equilibre-parcours-illustration-shape'>
                                        <div className='equilibre-parcours-illustration'>
                                            <img src={EQIphones} alt='Equilibre Iphones Parcours' className='object-contain' style={{ width: '100%' }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-12 xl:mt-0 xl:pl-8'>
                                    <ul className='equilibre-tag-list'>
                                        <li>
                                            <img src={EQLIFood} alt='Equilibre Food Tag' />
                                            <div>
                                                <span>
                                                    Alimentation
                                                </span>
                                                <p>
                                                    Manger quoi ? Quand ? Pour quels bénéfices ?
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={EQLISport} alt='Equilibre Sport Tag' />
                                            <div>
                                                <span>
                                                    Activité physique
                                                </span>
                                                <p>
                                                    Envie de bouger, oui. Mais pourquoi et comment ?
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={EQLIStress} alt='Equilibre Food Tag' />
                                            <div>
                                                <span>
                                                    Gestion du stress
                                                </span>
                                                <p>
                                                    Comprendre les facteurs du stress pour mieux les anticiper, c'est possible.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={EQLISleep} alt='Equilibre Sleep Tag' />
                                            <div>
                                                <span>
                                                    Sommeil
                                                </span>
                                                <p>
                                                    Comprendre son corps pour retrouver une qualité de sommeil.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='equilibre-expert mt-40 xl:mt-58' style={{
                        marginBottom: '150px'
                    }}>
                        <a className="equilibre-anchor" id="experts"></a>
                        <div className='container mx-auto'>
                            <div className='equilibre-expert-shape p-8' style={{
                                background: 'linear-gradient(#01bea5, #3495b3)',
                                borderRadius: '40px'
                            }}>
                                <div className='text-center break-words' style={{
                                    color: 'white',
                                    marginTop: '40px',
                                    hyphens: 'auto'
                                }}>
                                    <div style={{
                                        fontSize: '40px',
                                        fontWeight: 700,
                                        lineHeight: '48px'
                                    }}>
                                        1 comité scientifique multidisciplinaire
                                    </div>
                                    <div style={{
                                        fontSize: '18px',
                                        lineHeight: '24px',
                                        marginTop: '10px'
                                    }}>
                                        Experts des <span style={{fontWeight: 700}}>sciences comportementales</span> et des <span style={{fontWeight: 700}}>programmes de prévention santé</span>
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 xl:grid-cols-3 gap-10 place-items-center xl:px-16 mb-14 sm:mb-0' style={{
                                    marginTop: '60px'
                                }}>
                                    <div className='flex flex-col'>
                                        <div style={{
                                            background: 'white',
                                            borderRadius: '20px',
                                            padding: '30px',
                                            width: '300px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <img src={EQExpertML} alt='Equilibre Expert Manon Lenain' style={{
                                                width: '150px'
                                            }} />
                                            <span style={{
                                                marginTop: '20px',
                                                fontWeight: 700,
                                                fontSize: '18px',
                                                lineHeight: '24px',
                                                color: '#2F2C4A'
                                            }}>
                                                Manon Lenain
                                            </span>
                                            <span style={{
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                color: '#353A60',
                                                opacity: '0.5',
                                                fontWeight: 600,
                                                textAlign: 'center'
                                            }}>
                                                Neuropsychologue
                                            </span>
                                            <img src={EQPasteur} alt='Equilibre Pasteur Logo' style={{
                                                width: '60px',
                                                marginTop: '20px'
                                            }} />
                                        </div>
                                        <div className='mt-10' style={{
                                            background: 'white',
                                            borderRadius: '20px',
                                            padding: '30px',
                                            width: '300px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <img src={EQExpertNU} alt='Equilibre Expert Nathalie Uytterhaegen' style={{
                                                width: '150px'
                                            }} />
                                            <span style={{
                                                marginTop: '20px',
                                                fontWeight: 700,
                                                fontSize: '18px',
                                                lineHeight: '24px',
                                                color: '#2F2C4A'
                                            }}>
                                                Nathalie Uytterhaegen
                                            </span>
                                            <span style={{
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                color: '#353A60',
                                                opacity: '0.5',
                                                fontWeight: 600,
                                                textAlign: 'center'
                                            }}>
                                                Diététicienne
                                            </span>
                                            <img src={EQPasteur} alt='Equilibre Pasteur Logo' style={{
                                                width: '60px',
                                                marginTop: '20px'
                                            }} />
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div style={{
                                            background: 'white',
                                            borderRadius: '20px',
                                            padding: '30px',
                                            width: '300px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <img src={EQExpertBT} alt='Equilibre Expert Beate Thormeier' style={{
                                                width: '150px'
                                            }} />
                                            <span style={{
                                                marginTop: '20px',
                                                fontWeight: 700,
                                                fontSize: '18px',
                                                lineHeight: '24px',
                                                color: '#2F2C4A'
                                            }}>
                                                Beate Thormeier
                                            </span>
                                            <span style={{
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                color: '#353A60',
                                                opacity: '0.5',
                                                fontWeight: 600,
                                                textAlign: 'center'
                                            }}>
                                                Experte en coaching motivationnel
                                            </span>
                                            <img src={badgeSharecare} alt='Equilibre Sharecare Logo' style={{
                                                width: '60px',
                                                marginTop: '20px'
                                            }} />
                                        </div>
                                    </div>
                                    <div className='flex flex-col'  >
                                        <div style={{
                                            background: 'white',
                                            borderRadius: '20px',
                                            padding: '30px',
                                            width: '300px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <img src={EQExpertAS} alt='Equilibre Expert Aurélien Souppez' style={{
                                                width: '150px'
                                            }} />
                                            <span style={{
                                                marginTop: '20px',
                                                fontWeight: 700,
                                                fontSize: '18px',
                                                lineHeight: '24px',
                                                color: '#2F2C4A'
                                            }}>
                                                Aurélien Souppez
                                            </span>
                                            <span style={{
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                color: '#353A60',
                                                opacity: '0.5',
                                                fontWeight: 600,
                                                textAlign: 'center'
                                            }}>
                                                Sophrologue
                                            </span>
                                            <img src={EQPasteur} alt='Equilibre Pasteur Logo' style={{
                                                width: '60px',
                                                marginTop: '20px'
                                            }} />
                                        </div>
                                        <div className='mt-8' style={{
                                            background: 'white',
                                            borderRadius: '20px',
                                            padding: '30px',
                                            width: '300px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <img src={EQExpertSP} alt='Equilibre Expert Simon Spychala' style={{
                                                width: '150px'
                                            }} />
                                            <span style={{
                                                marginTop: '20px',
                                                fontWeight: 700,
                                                fontSize: '18px',
                                                lineHeight: '24px',
                                                color: '#2F2C4A'
                                            }}>
                                                Simon Spychala
                                            </span>
                                            <span style={{
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                color: '#353A60',
                                                opacity: '0.5',
                                                fontWeight: 600,
                                                textAlign: 'center'
                                            }}>
                                                Éducateur Médico Sportif
                                            </span>
                                            <img src={EQPasteur} alt='Equilibre Pasteur Logo' style={{
                                                width: '60px',
                                                marginTop: '20px'
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='relative hidden justify-center sm:flex'>
                                    <img src={EQPodcast} alt='Equilibre Podcast Iphone' className='equilibre-expert-illustration mt-24 xl:mt-0 xl:mx-0 xl:absolute' style={{
                                        maxWidth: '550px'
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='pb-40'>
                        <a className="equilibre-anchor" id="kit-deploy"></a>
                        <div className='container mx-auto'>
                            <div className='text-center' style={{
                                    fontSize: '40px',
                                    fontWeight: 700,
                                    lineHeight: '48px',
                                    paddingBottom: '120px',
                                    paddingTop: '0',
                                    color: '#353A60'
                                }}>
                                    <span style={{color: '#00bfa5'}} >Dynamisez</span> la <span style={{color: '#00bfa5'}} >prévention santé</span> individuelle dans votre entreprise
                            </div>

                            <div className='grid grid-cols-1 xl:grid-cols-2 xl:gap-16 items-center xl:pt-12'>
                                <ul className='equilibre-custom-icon-list'>
                                    <li className='flex flex-row items-center p-4 bg-white mb-4' style={{ borderRadius: '20px' }}>
                                        <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                            <img src={EQLIThumb} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                        </div>
                                        <p className='flex-1'>
                                            Une <span className='equilibre-bold'>solution pour tous</span> et <span className='equilibre-bold'>simple d’accès</span>, pas d’installation à prévoir
                                        </p>
                                    </li>
                                    <li className='flex flex-row items-center p-4 bg-white mb-4' style={{ borderRadius: '20px' }}>
                                        <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                            <img src={EQLICheck} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                        </div>
                                        <p className='flex-1'>
                                            Des <span className='equilibre-bold'>contenus</span> et <span className='equilibre-bold'>parcours santé</span> conçus par des experts reconnus
                                        </p>
                                    </li>
                                    <li className='flex flex-row items-center p-4 bg-white mb-4' style={{ borderRadius: '20px' }}>
                                        <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                            <img src={EQLIBusiness} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                        </div>
                                        <p className='flex-1'>
                                            Un <span className='equilibre-bold'>kit de déploiement</span> pour un lancement réussi
                                        </p>
                                    </li>
                                    <li className='flex flex-row items-center p-4 bg-white mb-4' style={{ borderRadius: '20px' }}>
                                        <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                            <img src={EQLIDashboard} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                        </div>
                                        <p className='flex-1'>
                                            Un <span className='equilibre-bold'>tableau de bord anonymisé</span> et des outils pour animer le programme
                                        </p>
                                    </li>
                                    <li className='flex flex-row items-center p-4 bg-white' style={{ borderRadius: '20px' }}>
                                        <div className='relative mr-4' style={{ height: '45px', width: '45px' }}>
                                            <img src={EQLIServices} alt='Icon' className='absolute block max-w-none' style={{ left: '-19px', top: '-10px' }} />
                                        </div>
                                        <p className='flex-1'>
                                            <span className='equilibre-bold'>Complémentaire</span> à vos services existants
                                        </p>
                                    </li>
                                </ul>
                                <div className='hidden xl:block' style={{
                                    background: 'linear-gradient(180deg, #00BFA5 0%, #3495B4 100%)',
                                    borderRadius: '32px'
                                }}>
                                    <div className='grid grid-cols-2 gap-6 items-center content-center p-6'>
                                        <div className='h-full flex flex-col items-center'>
                                            <div className='h-1/2 w-full relative flex justify-center'>
                                                <img
                                                    src={EQIlLaptop} alt="Illustration"
                                                    className='absolute'
                                                    style={{ bottom: '25px' }}
                                                />
                                            </div>
                                            <div>
                                                <img src={EQIlLanding} alt="Illustration" />
                                            </div>
                                        </div>
                                        <div className='flex justify-center'>
                                            <img src={EQIlAd} alt="Illustration" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a className="equilibre-anchor" id="demo"></a>
                    <div className='text-center flex items-center justify-center flex-col relative overflow-hidden py-28' style={{
                        backgroundColor: '#e5ecee',
                        width: '100%',
                        minHeight: 'calc(100vh - 82px)'
                    }}>
                        <div className='absolute equilibre-video-shadow'></div>
                        <div className='container mx-auto'>
                            <div className='text-center' style={{
                                fontSize: '40px',
                                fontWeight: 700,
                                lineHeight: '48px',
                                paddingBottom: '120px',
                                paddingTop: '0',
                                color: '#353A60'
                            }}>
                                <span>
                                    <span>
                                        Découvrez le Programme
                                    </span>
                                    <img src={logo} alt="Logo Equilibre" className='inline-block mx-2' style={{
                                        height: '31px',
                                        marginTop: '-10px'
                                    }}/>
                                </span>
                                <br />
                                <span style={{color: '#00bfa5'}} >en vidéo</span>
                            </div>
                            <div
                                className='relative inline-block overflow-hidden'
                                style={{ borderRadius: '19px' }}
                                onContextMenu={e => e.preventDefault()}>

                                <video className='w-full' id="video" controls controlsList="nodownload" style={{
                                    maxWidth:"750px"
                                }}>
                                    <source src={EQVideo} type="video/mp4"/>
                                </video>
                                <div id='poster' className='absolute inset-0 flex items-center justify-center'>
                                    <img src={EQPoster} alt='Equilibre Poster Video' className='absolute block' style={{
                                        top: '-28px',
                                        left: '-33px',
                                        width: 'calc(100% + 66px)',
                                        height: 'calc(100% + 64px)',
                                        maxWidth: 'none'
                                    }} />
                                    <img src={EQPlay} alt='Equilibre Play Icon' className='absolute' style={{
                                        cursor: 'pointer'
                                    }} onClick={playVideo} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container mx-auto py-28'>
                        <a className="equilibre-anchor" id="contact"></a>
                        <div className='p-8 md:px-20 xl:px-28' style={{
                            background: 'linear-gradient(#01bea5, #3495b3)',
                            borderRadius: '40px'
                        }}>
                            <div style={{
                                color: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '40px'
                            }}>
                                <span style={{
                                    fontSize: '40px',
                                    fontWeight: 700,
                                    lineHeight: '48px'
                                }}>
                                    Nous contacter
                                </span>
                            </div>
                            <div className='grid grid-cols-1 xl:grid-cols-2' style={{
                                marginTop: '40px'
                            }}>
                                <div>
                                    <div className='break-words' style={{
                                        backgroundColor: 'white',
                                        border: '5px solid rgba(255, 255, 255, .3)',
                                        WebkitBackgroundClip: 'padding-box',
                                        backgroundClip: 'padding-box',
                                        borderRadius: '20px',
                                        padding: '20px',
                                        color: '#353A60'
                                    }}>
                                        <span style={{
                                            fontWeight: 700,
                                            fontSize: '24px',
                                            lineHeight: '32px'
                                        }}>Sharecare</span>
                                        <div style={{
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            fontWeight: 600,
                                            marginTop: '20px'
                                        }}>
                                            <span>8 rue de l'Hôtel de Ville</span><br/>
                                            <span>Morning 3ème étage</span><br/>
                                            <span>92200 Neuilly-sur-Seine</span>
                                        </div>
                                        <div style={{
                                            fontWeight: 700,
                                            fontSize: '18px',
                                            lineHeight: '24px',
                                            marginTop: '40px'
                                        }}>
                                            <a href='mailto:edouard.klein@sharecare.com'>edouard.klein@sharecare.com</a><br/>
                                            <a href='mailto:julie.padol@pasteur-lille.fr'>julie.padol@pasteur-lille.fr</a>
                                        </div>
                                    </div>
                                    <div style={{ color: 'white', fontStyle: 'italic', textAlign: 'justify', margin: '25px auto', width: '95%' }}>
                                        Ces données sont collectées par Sharecare France à la seule fin de recontacter les personnes intéressées par ses services.
                                        Pour plus d'information nous vous invitons à consulter notre <a className="underline" href="/rgpd">politique de protection des données</a>.
                                    </div>
                                </div>
                                <Formik
                                    initialValues={INITIAL_CONTACT_VALUES}
                                    validationSchema={CONTACT_US_SCHEMA}
                                    onSubmit={handleContact}
                                    >
                                    {({ isValid, isSubmitting, dirty }) => (

                                        <Form className="pt-10 xl:pt-0 xl:pl-10 equilibre-contact-form" style={{
                                            fontSize: '14px',
                                            lineHeight: '19px'
                                        }}>
                                            <div className='grid grid-cols-2 gap-4 sm:gap-8' style={{
                                                marginTop: '-30px'
                                            }}>
                                                <InputField
                                                    name="lastName"
                                                    placeholder="Nom"
                                                    namespaces={['equilibre']}
                                                />
                                                <InputField
                                                    name="firstName"
                                                    placeholder="Prénom"
                                                    namespaces={['equilibre']}
                                                />
                                            </div>
                                            <InputField
                                                name="from"
                                                placeholder="Adresse e-mail"
                                                namespaces={['equilibre']}
                                            />
                                            <InputField
                                                name="zip"
                                                placeholder="Code postal"
                                                namespaces={['equilibre']}
                                            />
                                            <InputField
                                                name="companyName"
                                                placeholder="Nom de la société"
                                                namespaces={['equilibre']}
                                            />
                                            <InputField
                                                type="textarea"
                                                name="message"
                                                placeholder="Message"
                                                namespaces={['equilibre']}
                                            />

                                            <div className='text-center'>
                                                <Button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                >
                                                    Envoyer
                                                </Button>
                                            </div>

                                            {contactSuccess && !isSubmitting && (
                                                <div className='mt-8'>
                                                {contactSuccess === 'SUCCESS' && (
                                                    <div className="px-6 py-4 text-gray-800 rounded-md bg-primary-light">
                                                        Votre message a bien été envoyé! Une personne de l'équipe vous contactera dans les 2 jours.
                                                    </div>
                                                )}
                                                {contactSuccess === 'ERROR' && (
                                                    <div className="px-6 py-4 text-white bg-red-400 rounded-md">
                                                        Une erreur s'est produite, veuillez réessayer plus tard.
                                                    </div>
                                                )}
                                                </div>
                                            )}
                                        </Form>
                                    )}
                                    </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </Page.Main>
        </Page>
    );
}

export default Index;